export default function ContarPerfiles(registros) {
  // Definir un objeto para almacenar el conteo de perfiles
  const conteoPerfiles = {};
  
  // Recorrer cada registro
  registros.forEach(registro => {
    const perfil = registro.perfil_cliente;

    if(perfil) {
      // Si el perfil no existe en el conteo, inicializarlo en 0
      if (!conteoPerfiles[perfil]) {
        conteoPerfiles[perfil] = 0;
      }
      // Incrementar el conteo para el perfil actual
      conteoPerfiles[perfil]++;
    }
  });

  // Convertir el objeto de conteo a un array de objetos con el formato requerido
  const resultado = Object.keys(conteoPerfiles).map(perfil => ({
    label: perfil,
    value: conteoPerfiles[perfil]
  }));

  return resultado;
}
