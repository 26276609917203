import React from 'react';

//utils
import convertirADinero from '../../Utils/convertirADinero';
import extraerPrimerNombre from '../../Utils/extraerPrimerNobre';

//styles
import "./RegistrosTable.scss"

const RegistrosTable = ({ data, small=false }) => {
  return (
    <div className={`registros-table-container ${small ? "small" : ""}`}>
      <table className="registros-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Cliente</th>
            <th>Producto</th>
            <th>Presupuesto</th>
            <th>Perfil</th>
          </tr>
        </thead>
        <tbody>
          {data.map((registro, index) => (
            <tr key={index}>
              <td>{registro.fecha_primera_cita}</td>
              <td>{registro?.nombre_cliente ? registro?.nombre_cliente : " "}</td>
              <td>{registro.producto}</td>
              <td>{registro?.presupuesto ? convertirADinero(registro?.presupuesto) : " "}</td>
              <td>{registro.perfil_cliente}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrosTable;
