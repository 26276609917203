function formatearNombre(nombreCompleto) {
    const partes = nombreCompleto.split(' ');
    const primerNombre = partes[0];
    const penultimoApellido = partes.length > 2 ? partes[partes.length - 2].substring(0, 4) : '';
    const ultimoApellido = partes.length > 1 ? partes[partes.length - 1].substring(0, 4) : '';
    return `${primerNombre} ${penultimoApellido} ${ultimoApellido}`.trim();
}



export default function ContarPagadosYEntrevistasPorAsesor(registros) {
    // Definir un objeto para almacenar el conteo de registros por asesor
    const conteoPorAsesor = {};

    // Recorrer cada registro
    registros.forEach(registro => {
        const uuidAsesor = registro.uuid_asesor;

        // Si el asesor no está en el conteo, inicializar su conteo
        if (!conteoPorAsesor[uuidAsesor]) {
            conteoPorAsesor[uuidAsesor] = {
                nombre_asesor: registro.nombre_asesor,
                segundas_citas: 0,
                primeras_citas: 0
            };
        }


        // Contar los registros de acuerdo a su estatus
        if (registro.estatus === "Pagado") {
            conteoPorAsesor[uuidAsesor].segundas_citas++;
            conteoPorAsesor[uuidAsesor].primeras_citas++; // Cuenta también como entrevista
        } else if (registro.estatus === "Solicitud") {
            conteoPorAsesor[uuidAsesor].segundas_citas++;
            conteoPorAsesor[uuidAsesor].primeras_citas++; // Cuenta también como entrevista
        } else if (registro.estatus === "Segunda Cita") {
            conteoPorAsesor[uuidAsesor].segundas_citas++;
            conteoPorAsesor[uuidAsesor].primeras_citas++; // Cuenta también como entrevista
        } else if (registro.estatus === "Primera Cita") {
            conteoPorAsesor[uuidAsesor].primeras_citas++; // Cuenta también como entrevista
        }

        // Añadir el shortName al registro del asesor
        conteoPorAsesor[uuidAsesor].short_name = formatearNombre(registro.nombre_asesor);
    });

    // Convertir el objeto de conteo a un array de objetos con los datos de cada asesor
    const resultado = Object.keys(conteoPorAsesor).map(uuid_asesor => ({
        name: conteoPorAsesor[uuid_asesor].nombre_asesor,
        short_name: conteoPorAsesor[uuid_asesor].short_name,
        segundas_citas: conteoPorAsesor[uuid_asesor].segundas_citas,
        primeras_citas: conteoPorAsesor[uuid_asesor].primeras_citas,
        uuid_asesor: uuid_asesor
    }));

    // Ordenar el resultado por el número de registros pagados (de mayor a menor)
    resultado.sort((a, b) => b.segundas_citas - a.segundas_citas);

    // Construir el objeto final con los atributos Asesores, Pagados y Entrevistas
    const objetoFinal = {
        Asesores: resultado.map(asesor => asesor.short_name),
        Segundas_citas: resultado.map(asesor => asesor.segundas_citas),
        Primeras_citas: resultado.map(asesor => asesor.primeras_citas)
    };


    return objetoFinal;
}
