export default function ContarSectores(registros) {
  // Definir un objeto para almacenar el conteo de sectores
  const conteoSectores = {};
  
  // Recorrer cada registro
  registros.forEach(registro => {
    const sector = registro.sector_cliente;

    if(sector) {
      // Si el sector no existe en el conteo, inicializarlo en 0
      if (!conteoSectores[sector]) {
        conteoSectores[sector] = 0;
      }
      // Incrementar el conteo para el sector actual
      conteoSectores[sector]++;
    }
  });

  // Convertir el objeto de conteo a un array de objetos con el formato requerido
  const resultado = Object.keys(conteoSectores).map(sector => ({
    label: sector,
    value: conteoSectores[sector]
  }));

  return resultado;
}
