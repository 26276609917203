export default function ContarEstatus(registros) {
    // Definir un objeto para almacenar el conteo de estatus
    const conteoEstatus = {};
  
    // Definir un array para mantener el orden de los estatus
    const ordenEstatus = ["Prospecto", "Primera Cita", "Segunda Cita", "Solicitud", "Pagado"];
    
    // Inicializar el conteo de estatus
    ordenEstatus.forEach((estatus, index) => {
      conteoEstatus[estatus] = 0;
      // Si no es el primer estatus, también contar los anteriores
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          conteoEstatus[estatus] += conteoEstatus[ordenEstatus[i]];
        }
      }
    });
  
    // Recorrer cada registro
    registros.forEach(registro => {
      // Obtener el índice del estatus en el orden definido
      const index = ordenEstatus.indexOf(registro.estatus);
      // Contar este estatus y todos los anteriores
      for (let i = 0; i <= index; i++) {
        conteoEstatus[ordenEstatus[i]]++;
      }
    });
  
    // Convertir el objeto de conteo a un array de objetos con el formato requerido
    const resultado = ordenEstatus.map(estatus => ({
      id: ordenEstatus.indexOf(estatus),
      value: conteoEstatus[estatus],
      label: estatus
    }));
  
    return resultado;
  }
  