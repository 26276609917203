import React, { useEffect, useState } from "react"


// import
import Swal from 'sweetalert2';


// imports from mui x charts
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from "@mui/x-charts";

// Componentes
import AsesoresTable from "../../Components/AsesoresTable/AsesoresTable";
import RegistrosTable from "../../Components/RegistrosTable/RegistrosTable";
import SelectPeriod from "../../Components/SelectPeriod/SelectPeriod";

// Utils
import ObtenerMesActual from "../../Utils/ObtenerMesActual";
import FiltrarRegistrosPorMes from "../../Utils/FiltrarRegistrosPorMes";
import filtrarRegistrosPorAsesor from "../../Utils/FiltrarRegistrosPorAsesor";
import ContarPagadosPorSemana from "../../Utils/ContarPagadosPorSemana";
import ContaEstatusPorAsesor from "../../Utils/ContarPagadosEstatusPorAsesor";
import ContarPerfiles from "../../Utils/ContarPerfiles";
import ContarSectores from "../../Utils/ContarSectores";



// styles
import "./Dashboard.scss"
import ContarEstatus from "../../Utils/ContarEstatus";



export default function ({ }) {
  //Charts Style palette
  const chartsPalette = [
    "#4b0082", // Índigo
    "#800080", // Púrpura
    "#9370db", // Púrpura Claro
    "#8a2be2", // Azul Violeta
    "#9400d3", // Violeta Oscuro
    "#9932cc", // Orquídea Oscura
    "#ba55d3", // Orquídea Media
    "#dda0dd", // Ciruela
    "#e6e6fa", // Lavanda
    "#d8bfd8"  // Cardo
  ];

  //Conexión con registros DB
  const SteinStore = require("stein-js-client");
  const APIRegistrosDB = new SteinStore(process.env.REACT_APP_REGISTROS_API);
  const APIAsesoresDB = new SteinStore(process.env.REACT_APP_MIEMBROS_API);


  //Variables para ibases de datos completas y ya filtradas para cada grafica
  const [RegistrosDB, setRegistrosDB] = useState(null)
  const [RegistrosPeriodo, setRegistrosPeriodo] = useState([])

  const [AsesoresDB, setAsesoresDB] = useState(null)

  //Estados de funcionamiento de gráficas generales
  const [estatusxAsesor, setEstatusxAsesor] = useState(null)
  const [ConteoDeRegistrosPorSemanaDB, setConteoDeRegistrosPorSemanaDB] = useState(null)
  const [chart2DB, setChart2DB] = useState(null)
  const [chart3DB, setChart3DB] = useState(null)
  const [chart4DB, setChart4DB] = useState(null)



  //Estados de funcionamiento para analizar un asesor individualmente
  const [analizarAsesor, setAnalizarAsesor] = useState(null)
  const [analizarAsesorRegistros, setAnalizarAsesorRegistros] = useState([])
  const [analizarAsesorRegistrosPorSemana, setAnalizarAsesorRegistrosPorSemana] = useState([])
  const [analizarAsesorChart2DB, setAnalizarAsesorChart2DB] = useState([])
  const [analizarAsesorChart3DB, setAnalizarAsesorChart3DB] = useState([])
  const [analizarAsesorChart4DB, setAnalizarAsesorChart4DB] = useState([])
  // const [analizarAsesorPorcentajes, setAnalizarAsesorPorcentajes] = useState([])




  //filtro 
  const [periodo_a_medir, setPeriodo_a_medir] = useState(ObtenerMesActual())


  // Funcionalidad del dashboard, activamos pestaña de carga
  const [loading, setLoading] = useState(true)
  const [initRender, setInitRender] = useState(1)




  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedAsesores, setPaginatedAsesores] = useState([]);
  const [paginatedPagados, setPaginatedPagados] = useState([]);
  const [paginatedEntrevistas, setPaginatedEntrevistas] = useState([]);
  const itemsPerPage = 10;





  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };







  useEffect(() => {
    if (estatusxAsesor && estatusxAsesor.Asesores) {
      const totalItems = estatusxAsesor.Asesores.length;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setCurrentPage(1); // Reset to first page when data changes
    }
  }, [estatusxAsesor]);

  useEffect(() => {
    if (estatusxAsesor && estatusxAsesor.Asesores) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedAsesores(estatusxAsesor.Asesores.slice(startIndex, endIndex));
      setPaginatedPagados(estatusxAsesor.Segundas_citas.slice(startIndex, endIndex));
      setPaginatedEntrevistas(estatusxAsesor.Primeras_citas.slice(startIndex, endIndex));
    }
  }, [estatusxAsesor, currentPage]);





  // Actualizaremos la data de las graficas casda que cambiemos la fecha a medir
  useEffect(() => {
    setInitRender(2)
  }, [periodo_a_medir])






  //if it is the fitrst render, now get the api for DBs
  useEffect(() => {

    if (initRender === 2) {

      APIRegistrosDB
        .read("Registros", {
          authentication: {
            username: process.env.REACT_APP_RECORDS_USERNAME,
            password: process.env.REACT_APP_RECORDS_PASSWORD
          }
        })
        .then(data => {
          let registrosDB = data;
          let registrosDelPeriodo = FiltrarRegistrosPorMes(registrosDB, periodo_a_medir);

          // Registros trimestrales
          setRegistrosPeriodo(registrosDelPeriodo)
          // // Raw data
          setRegistrosDB(registrosDelPeriodo)

          // // //total de registros por asesor
          setEstatusxAsesor(ContaEstatusPorAsesor(registrosDelPeriodo))
          // // //total de perfiles
          setChart2DB(ContarPerfiles(registrosDelPeriodo))
          // // //total de estatus como dirección
          setChart3DB(ContarEstatus(registrosDelPeriodo))
          // // //total de registros por productos 
          setChart4DB(ContarSectores(registrosDelPeriodo))


          APIAsesoresDB
            .read("Asesores", {
              authentication: {
                username: process.env.REACT_APP_MIEMBROS_API_USERNAME,
                password: process.env.REACT_APP_MIEMBROS_API_PASSWORD
              }
            })
            .then(data => {
              let asesoresDB = data;

              setAsesoresDB(asesoresDB)
              setLoading(false)
              setInitRender(3)

            })
            .catch(e => {
              console.error(e);
            });



        })

        .catch(e => {
          console.error(e);
        });
    }

  }, [initRender])



  useEffect(() => {
    //vamos a cambiar a 4 unicamente cuando hayamos utilizado el select
    if (initRender === 4) {
      // //reseteamos todas las variables
      setRegistrosPeriodo([])
      setEstatusxAsesor(null)
      setConteoDeRegistrosPorSemanaDB(null)
      setChart2DB(null)
      setChart4DB(null)

      // //Estados de funcionamiento para analizar un asesor individualmente
      setAnalizarAsesor(null)
      setAnalizarAsesorRegistros([])
      setAnalizarAsesorRegistrosPorSemana([])
      setAnalizarAsesorChart2DB([])
      setAnalizarAsesorChart4DB([])



      // //definimos ahora los nuevos registros del trimestr seleccionado
      let registrosDelPeriodo = FiltrarRegistrosPorMes(RegistrosDB, periodo_a_medir);
      setRegistrosPeriodo(registrosDelPeriodo)

      // //total de registros por asesor
      setEstatusxAsesor(ContaEstatusPorAsesor(registrosDelPeriodo))
      //grafica de perfiles
      setChart2DB(ContarPerfiles(registrosDelPeriodo))
      // //total de estatus
      setChart3DB(ContarEstatus(registrosDelPeriodo))
      // //total de registros por productos 
      setChart4DB(ContarSectores(registrosDelPeriodo))
    }

  }, [periodo_a_medir, initRender])





  // Cargando al ingresasr al dashboard, cuando carga las apis ya no
  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    } else {
      Swal.close()
      // Obtener el elemento div por su ID
      const miDiv = document.getElementById('dashboard');

      // Hacer que la página se desplace automáticamente hacia el div
      miDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading])




  // Busca registros por Asesor
  useEffect(() => {
    if (analizarAsesor) {
      // Obtener el elemento div por su ID
      const miDiv = document?.getElementById('searcher');

      // Hacer que la página se desplace automáticamente hacia el div
      miDiv?.scrollIntoView({ behavior: 'smooth' });


      const DBfiltrada = filtrarRegistrosPorAsesor(RegistrosDB, analizarAsesor.uuid);


      setAnalizarAsesorRegistros(DBfiltrada)
      setAnalizarAsesorChart2DB(ContarPerfiles(DBfiltrada))
      setAnalizarAsesorChart3DB(ContarEstatus(DBfiltrada))
      setAnalizarAsesorChart4DB(ContarSectores(DBfiltrada))
    }


  }, [analizarAsesor])










  return (<React.Fragment>
    <div id="dashboard" style={{ height: "40px" }} />

    {
      !loading &&
      <section className="step-wrap-container-dashboard">
        <div className="step-container">

          <SelectPeriod period={periodo_a_medir} setPeriod={setPeriodo_a_medir} setInitRender={setInitRender} />
          <div style={{ height: "20px" }} />

          <h1 className="h1" style={{ textAlign: "center" }}>
            Por Asesor - Mes {periodo_a_medir}
          </h1>

          <div style={{ height: "25px" }} />

          <BarChart
            series={[
              { data: paginatedPagados, color: "#9400d3", label: "Segundas Citas" },
              { data: paginatedEntrevistas, color: "#9370db", label: "Primeras Citas" },
            ]}

            height={650}
            layout="horizontal"
            yAxis={[{
              data: paginatedAsesores,
              scaleType: 'band',
            }]}
            margin={{ left: 160, top: 50, bottom: 50, right: 10 }}
            colors={chartsPalette}
          />

          <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span style={{ margin: '0 10px' }}>Página {currentPage} de {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Siguiente
            </button>
          </div>

        </div>
      </section>
    }




    <div style={{ height: "40px" }} />




    <section className="step-wrap-container step-wrap-container-dashboard">

      <div className="step-container">

        <div style={{ height: "10px" }} />

        <h1 className="h1" style={{ textAlign: "center" }}>
          Dashboard Agentes Tollocan
        </h1>

        <div style={{ height: "25px" }} />


        {
          (
            loading
          ) ?
            <div className="dashboard-container-skeleton" style={{ height: "664px", display: "grid", placeItems: "center" }}>
              <h2 className="h2">Cargando Información...</h2>
            </div>

            :
            <div className="dashboard-container">
              <div className="chart1">
                <h3 className="h3">Por Asesor - Mes {periodo_a_medir}</h3>

                <BarChart
                  // dataset={pagadosYEntrevistasXAsesor}
                  series={[
                    { data: paginatedPagados.length > 5 ? paginatedPagados.slice(0, 5) : paginatedPagados, color: "#9400d3", label: "Segundas Citas" },
                    { data: paginatedEntrevistas.length > 5 ? paginatedEntrevistas.slice(0, 5) : paginatedEntrevistas, color: "#9370db", label: "Primeras Citas" },
                  ]}

                  height={300}
                  layout="horizontal"
                  yAxis={[{
                    data: paginatedAsesores.length > 5 ? paginatedAsesores.slice(0, 5) : paginatedAsesores,
                    scaleType: 'band',
                  }]}
                  margin={{ left: 200, top: 50, bottom: 50, right: 10 }}
                  colors={chartsPalette}
                />
              </div>












              <div className="chart2">
                <h3 className="h3">Perfiles - Mes {periodo_a_medir}</h3>
                <PieChart
                  colors={chartsPalette}



                  series={[
                    {
                      data: chart2DB,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}



                  width={600}
                  height={300}
                />
              </div>









              <div className="chart3">
                <h3 className="h3">Estatus - Trimeste {periodo_a_medir}</h3>
                <div className="lineChartContainer">
                  <PieChart
                    colors={chartsPalette}



                    series={[
                      {
                        data: chart3DB,
                        innerRadius: 30,
                        outerRadius: 120,
                        paddingAngle: 2,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                      },
                    ]}



                    width={600}
                    height={300}
                  />
                </div>
              </div>










              <div className="chart4">
                <h3 className="h3">Sectores - Mes {periodo_a_medir}</h3>
                <PieChart
                  colors={chartsPalette}



                  series={[
                    {
                      data: chart4DB,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}




                  width={600}
                  height={300}
                />
              </div>
            </div>
        }































      </div>
    </section>
    {
      !loading &&
      <div className="step-wrap-container-searcher">
        <h1 className="h1">Analisis individual</h1>
        <AsesoresTable data={AsesoresDB} onAsesorClick={setAnalizarAsesor} />
      </div>
    }



    {
      analizarAsesor &&
      <>
        <div id="searcher" style={{ height: "40px" }} />

        <section className="step-wrap-container step-wrap-container-dashboard">

          <div className="step-container">

            <div style={{ height: "40px" }} />

            <h1 className="h1" style={{ textAlign: "center" }}>
              Desempeño del asesor
            </h1>

            <div style={{ height: "25px" }} />














            <div className="dashboard-container">
              <div className="chart1">
                <h3 className="h3">Pagado - Mes {periodo_a_medir}</h3>
                <div style={{ height: "20px" }} />
                <RegistrosTable data={analizarAsesorRegistros.filter(registro => registro?.estatus == "Pagado")} small />
              </div>












              <div className="chart2">
                <h3 className="h3">Perfiles - Mes {periodo_a_medir}</h3>
                <PieChart
                  colors={chartsPalette}



                  series={[
                    {
                      data: analizarAsesorChart2DB,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}




                  width={600}
                  height={300}
                />

              </div>








              <div className="chart3">
                <h3 className="h3">Estatus - Mes {periodo_a_medir}</h3>
                <div className="lineChartContainer">
                  <PieChart
                    colors={chartsPalette}



                    series={[
                      {
                        data: analizarAsesorChart3DB,
                        innerRadius: 30,
                        outerRadius: 120,
                        paddingAngle: 2,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                      },
                    ]}



                    width={600}
                    height={300}
                  />
                </div>
              </div>










              <div className="chart4">
                <h3 className="h3">Sectores - Mes {periodo_a_medir}</h3>
                <PieChart
                  colors={chartsPalette}



                  series={[
                    {
                      data: analizarAsesorChart4DB,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}




                  width={600}
                  height={300}
                />
              </div>





            </div>






























          </div>
        </section>
      </>
    }
  </React.Fragment>
  )
}