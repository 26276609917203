import React from 'react';

import './SelectPeriod.scss'


// Array de opciones de trimestres
function generatePeriodOptions() {
  const options = [];
  const añoActual = new Date().getFullYear();
  
  for (let mes = 6; mes <= 12; mes++) {
    const mesStr = mes.toString().padStart(2, '0'); // Asegurarse de que el mes tenga dos dígitos
    options.push({ label: `${mesStr}/${añoActual}`, value: `${mesStr}/${añoActual}` });
  }

  return options;
}

const periodOptions = generatePeriodOptions();

// Componente SelectPeriod
const SelectPeriod = ({ period, setPeriod, setInitRender }) => {
  return (
    <select className='SelectPeriod' value={period} onChange={(e) => {setInitRender(4); setPeriod(e.target.value)}}>
      {periodOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectPeriod;
