import { useEffect, useState } from "react";

// src
import logo from "../src/Images/IsotipoBlanco.png"

// import
import Swal from 'sweetalert2';

// components
import Dashboard from "./Pages/Dashboard/Dashboard";
import StepBtn from "./Components/StepBtn/StepBtn";
import LogIn from "./Pages/LogIn/LogIn";

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';


// styles
import "./Styles/Globals.scss"
import "./Styles/Steps.scss"
import "./Styles/Layout.scss"

function App() {
  // Form functionality states
  const [stepCounter, setStepCounter] = useState(0)
  const [ableNextStep, setAbleNextStep] = useState(false)

  // Login Data
  const [asesorData, setAsesorData] = useState({
    uuid: "",
    asesorCua: "",
    asesorName: "",
    asesorPhone: "",
    asesorEmail: ""
  })
  const [cua, setCua] = useState("")
  const [asesorName, setAsesorName] = useState("")
  const [asesorPhone, setAsesorPhone] = useState("")
  const [asesorEmail, setAsesorEmail] = useState("")


  // Props packs
  const StepsProps = {
    stepCounter, setStepCounter,
    ableNextStep, setAbleNextStep
  }
  const AsesorProps = { asesorData, setAsesorData, cua, asesorName, asesorPhone, asesorEmail, setCua, setAsesorName, setAsesorPhone, setAsesorEmail }


  //configuramos idioma para componentes prime react
  useEffect(() => {
    addLocale('es', {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Limpiar',
      //...
    });
    locale('es');
  }, [])









  return (<div className="layout">
    <div className="bg-overlay" />

    <header className="Header"><img onClick={() => window.location.href = "."} src={logo} /></header>

    <main className={"App " + (stepCounter < 2 && "LogInx")}>

        {/* 0 is for the Login Form */}
        {/* 1 is for waiting a response to the auth api */}
        {stepCounter < 2 && <LogIn {...AsesorProps} {...StepsProps} />}


        {stepCounter >= 2 && <Dashboard />}



        {
          stepCounter < 2 &&
          <StepBtn disabled={!ableNextStep} {...StepsProps} direction="right" />
        }


      </main>



  </div>);
}

export default App;
