export default function FiltrarRegistrosPorMes(registrosDB, mesYAnio) {
  // Obtener el mes y el año del string "MM/YYYY"
  const [mes, anio] = mesYAnio.split("/").map(Number);

  // Definir la fecha de inicio y fin del mes
  const fechaInicioMes = new Date(anio, mes - 1, 1, 0, 0, 0);
  const fechaFinMes = new Date(anio, mes, 0, 23, 59, 59);

  // Función para convertir la fecha y hora del formato dado a un objeto Date
  function convertirFechaHora(fechaHoraStr) {
    // Descomponer la cadena en fecha y hora
    const [fechaParte, horaParte] = fechaHoraStr.split('-');

    // Convertir la parte de la fecha de dd/mm/yyyy a mm/dd/yyyy
    const [dia, mes, año] = fechaParte.split('/');
    const fechaFormatoAmericano = `${mes}/${dia}/${año}`;

    // Convertir la hora AM/PM a formato 24 horas
    const [hora, minutos, ampm] = horaParte.match(/(\d+):(\d+)(AM|PM)/).slice(1);
    let hora24 = parseInt(hora, 10);
    if (ampm === 'PM' && hora24 < 12) hora24 += 12;
    if (ampm === 'AM' && hora24 === 12) hora24 = 0;

    // Crear una fecha con la hora en formato 24 horas
    return new Date(`${fechaFormatoAmericano} ${hora24}:${minutos}`);
  }

  // Filtrar los registros que caen dentro del rango del mes
  const registrosFiltrados = registrosDB.filter((registro, i) => {
    const fechaRegistro = convertirFechaHora(registro.fecha_primera_cita);
    return fechaRegistro >= fechaInicioMes && fechaRegistro <= fechaFinMes;
  });

  return registrosFiltrados;
}
